// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archiv-js": () => import("./../src/pages/archiv.js" /* webpackChunkName: "component---src-pages-archiv-js" */),
  "component---src-pages-impressum-html-js": () => import("./../src/pages/impressum.html.js" /* webpackChunkName: "component---src-pages-impressum-html-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-list-js": () => import("./../src/templates/blogPostList.js" /* webpackChunkName: "component---src-templates-blog-post-list-js" */)
}


module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Edy-B(Log)","short_name":"Edy-B(log)","start_url":"/","background_color":"#5F5F5F","theme_color":"#5F5F5F","display":"standalone","icon":"src/images/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8d105d611f3c531dc506941c36374094"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "typeface-droid-serif"

var Masonry = require("masonry-layout")
var imagesLoaded = require("imagesloaded")

export const onRouteUpdate = ({ location }) => {
	if (location.pathname === '/impressum.html') {
		var b64 = document.querySelector("#b64")
		if (b64) {
			b64.innerHTML = Buffer.from(b64.innerHTML.trim(), "base64").toString("utf8")
		}
	}

	if (location.pathname === '/' || location.pathname.indexOf('/blog/page') === 0) {
		var articles = document.querySelector("#articles")

		if (articles) {
			imagesLoaded(articles, function() {
				new Masonry(articles, {
					itemSelector: "article",
					gutter: 20,
					isAnimated: true,
					columnWidth: 460,
				})
			})
		}
	}
}
